import React from "react";

const Icon = ({ className, children }) => (
    <span className={`${className} flex items-center justify-center h-12 w-12 rounded-md bg-highlight text-white`}>
        {children}
    </span>
);

export default Icon;

