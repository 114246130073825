import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";
import GiftIcon from "@icons/Gift";

export default function Microgifts({ reverse }) {
  const images = useStaticQuery(graphql`
    query {
      base: file(name: { eq: "microgifts" }) {
        sharp: childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "microgifts" }) {
        sharp: childImageSharp {
          fixed(width: 446) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.base.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px)`,
    },
  ];

  return (
    <Fixed description={<Description/>} image={sources} reverse={!!reverse} />
  );
};

const Description = () => (
  <div>
    <div>
      <GiftIcon />
    </div>
    <div className="mt-6">
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Incentives | Recognition | Rewards
        </span>
        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Digital-card MicroGifts instantly reward your customers and employees
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Take engagement to the next level where behaviours can be instantly recognised, success celebrated frequently or loyalty rewarded immediately.
        WAIVPAY&rsquo;s MicroGifts is the new platform for rewards, recognition and incentives.
        Instant delivery of a digital-wallet MasterCard, that is beautifully customised to your business and brand.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Pair with <Link className="text-highlight" to="/#qube">WAIVPAY Qube</Link> for complete end-to-end insight of customer loyalty, reporting and analytics.
      </p>
      <div className="mt-6">
        <StartButton to="/microgifts"/>
      </div>
    </div>
  </div>
);
